import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { FC, PropsWithChildren, useEffect } from 'react';
import { setTag, setUser, withProfiler } from '@sentry/react';
import app from '@/utils/app';
import { selectPrivateUser } from '@/redux/privateAuth.slice';

const SentryProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const { i18n } = useTranslation();
  const privateUser = useSelector(selectPrivateUser);

  useEffect(() => {
    setTag('idk.lang', i18n.language);
  }, [i18n]);

  useEffect(() => {
    setTag('idk.env', app.env);
  }, []);

  useEffect(() => {
    if (privateUser) {
      setUser({ username: 'PrivateUser()' });
    } else {
      setUser(null);
    }
  }, [privateUser]);

  return <>{children}</>;
};

export default withProfiler(SentryProvider);
