import { styled } from 'styled-components';
import { Link, matchPath, useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { isDefined } from '@idk-web/core-utils';
import {
  useBreakPoint,
  hostname,
  Styling,
  HamburgerButton,
  ResponsiveImage,
  Divider,
  Box,
  useDialog,
  SecondaryButton,
  MobileNavDrawer,
  HeaderNavigation,
  MobileProfileMenu,
  ProfileMenu,
} from '@idk-web/core-ui';
import {
  selectPrivateLoginStatus,
  selectPrivateUser,
} from '@/redux/privateAuth.slice';
import { usePrivateLogin } from '@/hooks/private/login/usePrivateLogin';
import TopUpDialog from '@/components/private/topup/TopUpDialog';
import routes from '@/components/private/routes';
import LanguageSelect from '@/components/common/input/LanguageSelect';
import LogoutDialog from '@/components/common/dialog/LogoutDialog';
import idkollen from '@/assets/svg/idkollen.svg';
import identisure from '@/assets/svg/identisure.svg';

const Container = styled.header`
  position: relative;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  min-height: 80px;
  z-index: ${Styling.zIndex('header')};
  box-shadow: ${Styling.shadow('header')};
  background-color: ${({ theme }) => theme.palette.grayscale.white};
  padding: 0 ${Styling.spacing(8)};
  @media (max-width: ${Styling.breakpoint('lg')}px) {
    padding: 0 ${Styling.spacing(4)};
  }
  @media (max-width: ${Styling.breakpoint('sm')}px) {
    padding: 0 ${Styling.spacing(2)};
  }
`;

const Section = styled(Box).attrs(({ theme }) => ({
  alignX: 'center',
  alignY: 'center',
  spacing: theme.spacing[2],
}))``;

const CenterSection = styled(Section)`
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

const HeaderText = styled(Box).attrs({
  alignX: 'center',
  alignY: 'center',
})`
  ${Styling.typography('h2')};
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.palette.secondary.main};
`;

const Logo = styled(ResponsiveImage).attrs({
  alt: 'Logo', // TODO translate
})`
  max-width: 160px;
  max-height: 120px;
  padding: ${Styling.spacing(2)} 0;
`;

const ShortDivider = styled(Divider)`
  height: 16px;
`;

const LogoutText = styled.div`
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: bold;
`;

const Header: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const dialog = useDialog();
  const xxl = useBreakPoint('xxl');
  const xl = useBreakPoint('xl');
  const login = usePrivateLogin();
  const loginStatus = useSelector(selectPrivateLoginStatus);
  const user = useSelector(selectPrivateUser);
  const isLoggedIn = loginStatus === 'LOGGED_IN';
  const paths = useMemo(
    () => [
      { href: routes.idCheckNew, title: t('private.header.nav.new') },
      { href: routes.idCheckHistory, title: t('private.header.nav.history') },
    ],
    [t],
  );
  const currentPath = useMemo(() => {
    return paths.find((path) =>
      matchPath(location.pathname, { path: path.href }),
    );
  }, [location, paths]);
  const logo = useMemo(() => {
    switch (hostname.tld) {
      case 'se':
        return idkollen;
      default:
        return identisure;
    }
  }, [isLoggedIn, user]);

  useEffect(() => {
    if (xl.above) {
      setMobileMenuOpen(false);
    }
  }, [xl]);

  const toggleMobileMenu = () => setMobileMenuOpen((open) => !open);

  const handleTopUp = () => dialog.show(TopUpDialog);

  const handleGoToProfile = () => history.push(routes.profile);

  const handleLogout = () => {
    const handleOk = () => login.logout();

    dialog.show((props) => <LogoutDialog {...props} onOk={handleOk} />);
  };

  const menuItems = [
    {
      content: t('private.header.menu.profile.item'),
      onClick: handleGoToProfile,
    },
    xxl.below
      ? {
          content: t('private.header.menu.topup.item'),
          onClick: handleTopUp,
        }
      : undefined,
    'divider' as const,
    {
      content: <LogoutText>Log out</LogoutText>,
      onClick: handleLogout,
    },
  ].filter(isDefined);

  return (
    <>
      {isLoggedIn && (
        <MobileNavDrawer
          open={mobileMenuOpen}
          onClose={() => setMobileMenuOpen(false)}
          items={paths}
          logo={logo}
        />
      )}
      <Container>
        {xl.below && isLoggedIn ? (
          <Section>
            <HamburgerButton onClick={toggleMobileMenu} />
          </Section>
        ) : (
          <Link to={routes.idCheckNew}>
            <Logo src={logo} />
          </Link>
        )}
        <CenterSection>
          {isLoggedIn && xl.above && <HeaderNavigation paths={paths} />}
          {isLoggedIn && xl.below && (
            <HeaderText>{currentPath?.title}</HeaderText>
          )}
        </CenterSection>
        <Section>
          {isLoggedIn && xxl.above && (
            <SecondaryButton
              text={t('private.header.menu.topup.item')}
              onClick={handleTopUp}
            />
          )}
          <LanguageSelect />
          {isLoggedIn && (
            <>
              <ShortDivider orientation="vertical" />
              {xl.below ? (
                <MobileProfileMenu items={menuItems} />
              ) : (
                <ProfileMenu
                  username={user?.name ?? user?.email ?? 'Anonymous'}
                  items={menuItems}
                />
              )}
            </>
          )}
        </Section>
      </Container>
    </>
  );
};

export default Header;
