import { DefaultRootState, PrivateAuthState } from 'react-redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PrivateAppLoginResponse, PrivateUser } from '@idk-web/api';

const initialState: PrivateAuthState = {
  status: 'LOGGED_OUT',
  user: null,
  token: null,
};

export const privateAuthSlice = createSlice({
  name: 'private/auth',
  initialState: initialState as PrivateAuthState,
  reducers: {
    logout(): PrivateAuthState {
      return initialState;
    },
    login(
      state,
      action: PayloadAction<
        Extract<PrivateAppLoginResponse, { status: 'COMPLETED' }>
      >,
    ): PrivateAuthState {
      const payload = action.payload;

      return {
        status: 'LOGGED_IN',
        user: {
          name: payload.user.name,
          email: payload.user.email ?? '',
          credits: payload.user.credits,
          termsOfServiceAccepted: payload.user.termsOfServiceAccepted,
          hasValidatedEmail: payload.user.hasValidatedEmail,
        },
        token: payload.token,
      };
    },
    updateUser(state, action: PayloadAction<PrivateUser>): void {
      if (state.user) {
        state.user.name = action.payload.name;
        state.user.email = action.payload.email ?? '';
        state.user.credits = action.payload.checksLeft;
        state.user.termsOfServiceAccepted =
          action.payload.termsOfServiceAccepted;
        state.user.hasValidatedEmail = action.payload.hasValidatedEmail;
      }
    },
  },
});

export const { logout, login, updateUser } = privateAuthSlice.actions;

export const selectPrivateLoginStatus = (state: DefaultRootState) =>
  state.privateAuth.status;
export const selectPrivateUser = (state: DefaultRootState) =>
  state.privateAuth.user;

export default privateAuthSlice.reducer;
