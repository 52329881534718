import { useSelector, PrivateUser, useDispatch } from 'react-redux';
import { useCallback, useEffect, useMemo } from 'react';
import { getOwnPrivateUser } from '@idk-web/api';
import { selectPrivateUser, updateUser } from '@/redux/privateAuth.slice';
import { usePrivateLogin } from '@/hooks/private/login/usePrivateLogin';

export type User = PrivateUser & {
  /**
   * Reloads the current user globally
   */
  reload(): Promise<void>;
};

/**
 * Returns the currently logged in private app user.
 *
 * This hook may only be used in components that are only mounted when a private
 * user is guaranteed to logged in.
 */
export function usePrivateUser(): User {
  const auth = usePrivateLogin();
  const dispatch = useDispatch();
  const privateUser = useSelector(selectPrivateUser);
  const reload = useCallback(async () => {
    if (!privateUser) {
      console.warn('Tried to reload user when user is missing in state');
      return;
    }

    const user = await getOwnPrivateUser();
    dispatch(updateUser(user));
  }, [privateUser]);

  useEffect(() => {
    if (!privateUser) {
      void auth.logout();
    }
  }, [privateUser]);

  return useMemo(
    () => ({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      ...privateUser!,
      reload,
    }),
    [privateUser, reload],
  );
}
