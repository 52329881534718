import { Redirect, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import React, { FC } from 'react';
import { SentryRoute } from '@/sentry';
import { selectPrivateLoginStatus } from '@/redux/privateAuth.slice';
import routes from '@/components/private/routes';

export type AuthenticatedRouteProps = RouteProps;

/**
 * A route which redirects to the login page if the current user is logged out.
 */
const AuthenticatedRoute: FC<AuthenticatedRouteProps> = (props) => {
  const loginStatus = useSelector(selectPrivateLoginStatus);

  if (loginStatus === 'LOGGED_OUT') {
    return <Redirect to={routes.login} />;
  }

  return <SentryRoute {...props} />;
};

export default AuthenticatedRoute;
