import { styled } from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import React, { FC, useState } from 'react';
import { Sentry } from '@idk-web/core-utils';
import {
  hostname,
  Styling,
  StandardCard,
  Overlay,
  Box,
  useDialog,
  DialogProps,
  SpinningCircleLoader,
  DangerButton,
  PrimaryButton,
  ErrorDialog,
} from '@idk-web/core-ui';
import { initCheckout } from '@idk-web/api';
import { translateError } from '@/utils/error';
import { Country } from '@/types/idkollen';

const DialogCard = styled(StandardCard)`
  border-top: 8px solid ${({ theme }) => theme.palette.primary.main};
  margin: 0;
  max-width: 500px;
`;

const Header = styled.h2`
  ${Styling.typography('h2')}
  text-align: center;
  color: ${({ theme }) => theme.palette.primary.main};
`;

const ButtonGroup = styled(Box).attrs(({ theme }) => ({
  direction: 'vertical',
  alignX: 'center',
  spacing: theme.spacing[0.75],
}))`
  margin-top: ${Styling.spacing(4)};
`;

const Button = styled(PrimaryButton).attrs({
  size: 'md',
})`
  width: 100%;
`;

const ButtonSection = styled(Box).attrs(({ theme }) => ({
  alignX: 'center',
  spacing: theme.spacing[4],
}))`
  margin-top: ${Styling.spacing(4)};
`;

const Text = styled.div`
  ${Styling.typography('body')}
  font-size: 0.9rem;
  font-style: italic;
  color: #888888; // TODO move to theme
  margin-top: ${Styling.spacing(2)};

  & a {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

let country: Country;
switch (hostname.tld) {
  case 'no':
    country = Country.NORWAY;
    break;
  case 'dk':
    country = Country.DENMARK;
    break;
  case 'fi':
    country = Country.FINLAND;
    break;
  default:
    country = Country.SWEDEN;
    break;
}

export type TopUpProps = {
  onSelect?(amount: number): void;
  onCancel?(): void;
} & DialogProps;

const TopUpDialog: FC<TopUpProps> = ({ onCancel, onClose }) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const dialog = useDialog();

  const handleSelect = async (amount: number) => {
    setLoading(true);

    try {
      window.location.href = await initCheckout({ amount, country });
      onClose?.();
    } catch (e) {
      Sentry.reportError('Failed to create checkout session', { error: e });
      dialog.show((props) => (
        <ErrorDialog {...props} text={translateError(t, e)} />
      ));
    }
  };

  const handleCancel = () => {
    onCancel?.();
    onClose?.();
  };

  return (
    <>
      <DialogCard>
        <Overlay show={loading} color="rgba(255, 255, 255, 0.5)">
          <Box
            alignX="center"
            alignY="center"
            style={{ position: 'absolute', width: '100%', height: '100%' }}
          >
            <SpinningCircleLoader />
          </Box>
        </Overlay>
        <Header>Fyll på konto</Header>
        <ButtonGroup>
          <Button
            text={t('private.topup.items.three')}
            onClick={() => handleSelect(3)}
          />
          <Button
            text={t('private.topup.items.ten')}
            onClick={() => handleSelect(10)}
          />
        </ButtonGroup>
        <Text>
          <Trans
            i18nKey="private.topup.text"
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            components={{ url: <a href="https://www.stripe.com/se/" /> }}
          />
        </Text>
        <ButtonSection>
          <DangerButton text={t('common.cancel')} onClick={handleCancel} />
        </ButtonSection>
      </DialogCard>
    </>
  );
};

export default TopUpDialog;
