import { wait } from '@idk-web/core-utils';
import { LocalStorage } from '@idk-web/core-ui';
import {
  denmarkPrivateLogin,
  finlandPrivateLogin,
  getPrivateDenmarkLoginUrl,
  getPrivateFinlandLoginUrl,
  getPrivateLoginResult,
  getPrivateNorwayLoginUrl,
  norwayPrivateLogin,
  PrivateAppLoginResponse,
  swedenPrivateLogin,
  CodeLoginRequest,
} from '@idk-web/api';

const POLL_INTERVAL_MS = 1000;
const POLL_TIMEOUT_MS = 10 * 60 * 1000;

type InitCallback = (
  data: Extract<PrivateAppLoginResponse, { status: 'IN_PROGRESS' }>,
) => void;
type CompleteCallback = (
  data: Extract<PrivateAppLoginResponse, { status: 'COMPLETED' }>,
) => void;
type ErrorCallback = (error: unknown) => void;
type ProgressCallback = (
  data: Extract<PrivateAppLoginResponse, { status: 'IN_PROGRESS' }>,
) => void;
type InitFunction = () => Promise<
  Extract<PrivateAppLoginResponse, { status: 'IN_PROGRESS' }>
>;
type CancelFunction = (ref: string) => Promise<void>;
type GetStatusFunction = (ref: string) => Promise<PrivateAppLoginResponse>;

export type PendingPrivateLoginRequest = {
  onInit(callback: InitCallback): PendingPrivateLoginRequest;
  onComplete(callback: CompleteCallback): PendingPrivateLoginRequest;
  onError(callback: ErrorCallback): PendingPrivateLoginRequest;
  onProgress(callback: ProgressCallback): PendingPrivateLoginRequest;
  start(): StartedPrivateLoginRequest;
};

export type StartedPrivateLoginRequest = {
  wait(): Promise<void>;
  cancel(): void;
};

export function sweden(): PendingPrivateLoginRequest {
  return start(
    swedenPrivateLogin,
    async () => {
      // Not supported
    },
    getPrivateLoginResult,
  );
}

export function norway(window: Window): PendingPrivateLoginRequest {
  return startExternal(window, getPrivateNorwayLoginUrl, norwayPrivateLogin);
}

export function denmark(window: Window): PendingPrivateLoginRequest {
  return startExternal(window, getPrivateDenmarkLoginUrl, denmarkPrivateLogin);
}

export function finland(window: Window): PendingPrivateLoginRequest {
  return startExternal(window, getPrivateFinlandLoginUrl, finlandPrivateLogin);
}

function startExternal(
  window: Window,
  getLoginUrl: () => Promise<string>,
  login: (
    req: CodeLoginRequest,
  ) => Promise<Extract<PrivateAppLoginResponse, { status: 'COMPLETED' }>>,
): PendingPrivateLoginRequest {
  return start(
    async () => {
      const url = await getLoginUrl();
      window.location.href = url;
      return { status: 'IN_PROGRESS', ref: url };
    },
    async () => window.close(),
    async (ref) => {
      const code = LocalStorage.getItem('code');

      if (code) {
        LocalStorage.removeItem('code');
      } else if (window.closed) {
        return { status: 'FAILED', error: 'cancelled', ref };
      } else {
        return { status: 'IN_PROGRESS', ref };
      }

      return login({ code });
    },
  );
}

function start(
  init: InitFunction,
  cancel: CancelFunction,
  getStatus: GetStatusFunction,
): PendingPrivateLoginRequest {
  const initCallbacks: InitCallback[] = [];
  const completeCallbacks: CompleteCallback[] = [];
  const errorCallbacks: ErrorCallback[] = [];
  const progressCallbacks: ProgressCallback[] = [];
  const controller = new AbortController();

  return {
    onInit(callback) {
      initCallbacks.push(callback);
      return this;
    },
    onComplete(callback) {
      completeCallbacks.push(callback);
      return this;
    },
    onError(callback) {
      errorCallbacks.push(callback);
      return this;
    },
    onProgress(callback) {
      progressCallbacks.push(callback);
      return this;
    },
    start() {
      const promise = run(
        init,
        cancel,
        getStatus,
        (data) => initCallbacks.forEach((f) => f(data)),
        (data) => completeCallbacks.forEach((f) => f(data)),
        (error) => errorCallbacks.forEach((f) => f(error)),
        (data) => progressCallbacks.forEach((f) => f(data)),
        controller,
      );

      return {
        wait: () => promise,
        cancel: () => controller.abort(),
      };
    },
  };
}

async function run(
  init: InitFunction,
  cancel: CancelFunction,
  getStatus: GetStatusFunction,
  onInit: InitCallback,
  onComplete: CompleteCallback,
  onError: ErrorCallback,
  onProgress: ProgressCallback,
  controller: AbortController,
): Promise<void> {
  let timeLeft = POLL_TIMEOUT_MS;
  let ref: string;

  try {
    const response = await init();
    ref = response.ref;

    onInit(response);
  } catch (e) {
    onError(e);
    throw e;
  }

  controller.signal.addEventListener('abort', () => cancel(ref));

  while (timeLeft > 0) {
    let response: PrivateAppLoginResponse;
    try {
      response = await getStatus(ref);
    } catch (e) {
      await wait(POLL_INTERVAL_MS);
      continue;
    }

    if (controller.signal.aborted || timeLeft < 0) {
      break;
    }

    switch (response.status) {
      case 'FAILED':
        onError(response.error);
        throw new Error(response.error);
      case 'COMPLETED': {
        onComplete(response);
        return;
      }
    }

    timeLeft -= POLL_INTERVAL_MS;

    onProgress(response);

    await wait(POLL_INTERVAL_MS);

    if (controller.signal.aborted || timeLeft < 0) {
      break;
    }
  }

  if (controller.signal.aborted) {
    onError('cancelled');
    throw new Error('cancelled');
  } else {
    onError('timed out');
    throw new Error('timed out');
  }
}
