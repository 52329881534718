import { styled } from 'styled-components';
import { Redirect, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { FC, Suspense, useEffect } from 'react';
import { Axios } from '@idk-web/core-utils';
import {
  useVisibility,
  useQueryParams,
  Box,
  useDialog,
  AcknowledgeDialog,
  ErrorBoundary,
} from '@idk-web/core-ui';
import { SentryRoute } from '@/sentry';
import { selectPrivateLoginStatus } from '@/redux/privateAuth.slice';
import { usePrivateUser } from '@/hooks/private/usePrivateUser';
import { usePrivateLogin } from '@/hooks/private/login/usePrivateLogin';
import routes from '@/components/private/routes';
import UnauthenticatedRoute from '@/components/private/UnauthenticatedRoute';
import TermsOfServiceApproval from '@/components/private/TermsOfServiceApproval';
import Layout from '@/components/private/Layout';
import AuthenticatedRoute from '@/components/private/AuthenticatedRoute';
import ScheduledMaintenanceMessage from '@/components/common/ScheduledMaintenanceMessage';
import BrowserSupportMessage from '@/components/common/BrowserSupportMessage';

const ErrorScreen = React.lazy(() => import('@/components/error/ErrorScreen'));
const LoginCallback = React.lazy(
  () => import('@/components/common/auth/LoginCallback'),
);
const LoginScreen = React.lazy(
  () => import('@/components/private/login/LoginScreen'),
);
const EmailValidationScreen = React.lazy(
  () => import('@/components/private/email/EmailValidationScreen'),
);
const EmailRequestScreen = React.lazy(
  () => import('@/components/private/email/EmailRequestScreen'),
);
const HomeScreen = React.lazy(
  () => import('@/components/private/new/NewRequestScreen'),
);
const HistoryScreen = React.lazy(
  () => import('@/components/private/history/HistoryScreen'),
);
const AuthScreen = React.lazy(
  () => import('@/components/private/auth/AuthScreen'),
);
const ProfileScreen = React.lazy(
  () => import('@/components/private/profile/ProfileScreen'),
);

const Container = styled(Box).attrs({
  direction: 'vertical',
})``;

type QueryParams = {
  success: string;
};

const PrivateApp: FC = () => {
  const { t } = useTranslation();
  const params = useQueryParams<QueryParams>();
  const dialog = useDialog();
  const auth = usePrivateLogin();
  const user = usePrivateUser();
  const loginStatus = useSelector(selectPrivateLoginStatus);
  const visibility = useVisibility();

  useEffect(() => {
    if (loginStatus === 'LOGGED_IN' && visibility === 'visible') {
      (async () => {
        try {
          await user.reload();
        } catch (e) {
          if (
            Axios.isError(e) &&
            e.response &&
            Axios.is4xxResponse(e.response)
          ) {
            await auth.logout();
          } else {
            console.error('Failed to reload private user', e);
          }
        }
      })();
    }
  }, [loginStatus, visibility]);

  useEffect(() => {
    if (params.get('success') !== null) {
      dialog.show((props) => (
        <AcknowledgeDialog
          {...props}
          header={t('private.topup.success.header')}
          text={t('private.topup.success.text')}
          onClose={() => {
            params.set('success', null);
            props.onClose?.();
          }}
        />
      ));
    }
  }, []);

  return (
    <Container>
      <ErrorBoundary fallback={ErrorScreen}>
        <BrowserSupportMessage />
        <ScheduledMaintenanceMessage />
        <Layout>
          <Suspense fallback={null}>
            <Switch>
              <SentryRoute path={routes.loginCallback}>
                <LoginCallback redirect={routes.login} />
              </SentryRoute>
              <SentryRoute
                path={routes.validateEmail}
                component={EmailValidationScreen}
              />
              <UnauthenticatedRoute
                exact
                path={routes.login}
                component={LoginScreen}
              />
              <AuthenticatedRoute
                exact
                path={routes.idCheckNew}
                render={() => {
                  if (loginStatus === 'LOGGED_IN' && !user?.hasValidatedEmail) {
                    return <EmailRequestScreen />;
                  }

                  if (
                    loginStatus === 'LOGGED_IN' &&
                    !user.termsOfServiceAccepted
                  ) {
                    // Users are required to accept the ToS
                    return <TermsOfServiceApproval />;
                  }

                  return <HomeScreen />;
                }}
              />
              <AuthenticatedRoute
                exact
                path={routes.idCheckHistory}
                component={HistoryScreen}
              />
              <AuthenticatedRoute
                exact
                path={routes.profile}
                component={ProfileScreen}
              />
              <SentryRoute exact path={routes.auth} component={AuthScreen} />
              <SentryRoute
                render={() => {
                  if (loginStatus === 'LOGGED_OUT') {
                    return <Redirect to={routes.login} />;
                  }

                  return <Redirect to={routes.idCheckNew} />;
                }}
              />
            </Switch>
          </Suspense>
        </Layout>
      </ErrorBoundary>
    </Container>
  );
};

export default PrivateApp;
