import { Redirect, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import React, { FC } from 'react';
import { SentryRoute } from '@/sentry';
import { selectPrivateLoginStatus } from '@/redux/privateAuth.slice';
import routes from '@/components/private/routes';

export type UnauthenticatedRouteProps = RouteProps;

/**
 * A route which redirects to the private app if the current user is logged in.
 */
const UnauthenticatedRoute: FC<UnauthenticatedRouteProps> = (props) => {
  const loginStatus = useSelector(selectPrivateLoginStatus);

  if (loginStatus === 'LOGGED_IN') {
    return <Redirect to={routes.idCheckNew} />;
  }

  return <SentryRoute {...props} />;
};

export default UnauthenticatedRoute;
