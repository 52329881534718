export enum Country {
  SWEDEN = 'SWEDEN',
  NORWAY = 'NORWAY',
  DENMARK = 'DENMARK',
  FINLAND = 'FINLAND',
}

export enum Language {
  ENGLISH = 'ENGLISH',
  SWEDISH = 'SWEDISH',
  NORWEGIAN = 'NORWEGIAN',
  DANISH = 'DANISH',
  FINNISH = 'FINNISH',
}

export enum AuthType {
  PASSWORD = 'PASSWORD',
  EID = 'EID',
  SSO = 'SSO',
}

export enum AuthProvider {
  BANKID = 'BANKID',
  BANKID_NO = 'BANKID_NO',
  MITID = 'MITID',
  FTN = 'FTN',
  FREJA = 'FREJA',
}

export enum ServiceType {
  TELEID = 'TELEID',
  TELESIGN = 'TELESIGN',
  SAFEMAIL = 'SAFEMAIL',
}

export enum PortalTab {
  TELEID = 'TELEID',
  TELESIGN = 'TELESIGN',
  SAFEMAIL = 'SAFEMAIL',
  ADMIN = 'ADMIN',
}

export enum SentAccess {
  NONE = 0,
  ONLY_ADMINS = 1,
  COMPANY_USERS = 2,
  ALL_USERS = 3,
}

export enum AdminPermission {
  VIEW_API_CUSTOMERS = 'VIEW_API_CUSTOMERS',
  MANAGE_API_CUSTOMERS = 'MANAGE_API_CUSTOMERS',
  VIEW_COMPANIES = 'VIEW_COMPANIES',
  MANAGE_COMPANIES = 'MANAGE_COMPANIES',
  VIEW_FORMS = 'VIEW_FORMS',
  MANAGE_FORMS = 'MANAGE_FORMS',
  MANAGE_PERMISSIONS = 'MANAGE_PERMISSIONS',
  MANAGE_SCHEDULED_MAINTENANCE = 'MANAGE_SCHEDULED_MAINTENANCE',
  VIEW_SCHEDULED_MAINTENANCE = 'VIEW_SCHEDULED_MAINTENANCE',
  VIEW_USERS = 'VIEW_USERS',
  MANAGE_USERS = 'MANAGE_USERS',
  CREATE_INVOICE = 'CREATE_INVOICE',
  VIEW_STATISTICS = 'VIEW_STATISTICS',
}
