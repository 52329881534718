import * as Yup from 'yup';
import { styled } from 'styled-components';
import { useTranslation } from 'react-i18next';
import React, { FC } from 'react';
import { useFormik, FormikProvider } from 'formik';
import { Sentry } from '@idk-web/core-utils';
import {
  loseFocus,
  Styling,
  flexGap,
  StandardCard,
  Box,
  useDialog,
  useNotification,
  FilledCheckbox,
  OkButton,
  ErrorDialog,
} from '@idk-web/core-ui';
import { acceptPrivateAppTermsOfServices } from '@idk-web/api';
import { translateError } from '@/utils/error';
import { usePrivateUser } from '@/hooks/private/usePrivateUser';
import { DEFAULT_NOTIFICATION_AUTO_HIDE_MS } from '@/config';
import { FormField } from '@/components/common/input/FormField';

const Container = styled(Box).attrs({
  direction: 'vertical',
  alignX: 'center',
})`
  width: 100%;
`;

const Content = styled(Box).attrs(({ theme }) => ({
  direction: 'vertical',
  spacing: theme.spacing[5],
}))`
  width: 100%;
  padding: ${Styling.spacing(8)};
  @media (min-width: ${Styling.breakpoint('xl')}px) {
    max-width: 1000px;
  }
  @media (max-width: ${Styling.breakpoint('xl')}px) {
    width: 100%;
    padding: ${Styling.spacing(4)};
  }
  @media (max-width: ${Styling.breakpoint('sm')}px) {
    width: 100%;
    padding: ${Styling.spacing(1)};
  }
`;

const Form = styled.form``;

const Card = styled(StandardCard)`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: ${Styling.spacing(6)};
  ${({ theme }) => flexGap(theme.spacing[3])};
  @media (max-width: ${Styling.breakpoint('lg')}px) {
    padding: ${Styling.spacing(6, 4)};
  }
  @media (max-width: ${Styling.breakpoint('sm')}px) {
    padding: ${Styling.spacing(4, 2)};
  }
`;

const Text = styled(Box).attrs(({ theme }) => ({
  direction: 'vertical',
  spacing: theme.spacing[1],
}))``;

const Title = styled.h2`
  ${Styling.typography('h2')}
  color: ${({ theme }) => theme.palette.secondary.main};
`;

const Description = styled.div`
  ${Styling.typography('body')}
  color: ${({ theme }) => theme.palette.primary.text};
`;

const Link = styled.a`
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: bold;
`;

const TermsOfServiceApproval: FC = () => {
  const { t } = useTranslation();
  const dialog = useDialog();
  const notification = useNotification();
  const user = usePrivateUser();
  const formik = useFormik({
    initialValues: {
      approval: false,
    },
    validationSchema: Yup.object().shape({
      approval: Yup.boolean()
        .required(t('errors.field_is_required'))
        .oneOf([true], t('private.terms_of_service.must_agree')),
    }),
    async onSubmit() {
      loseFocus();

      try {
        await acceptPrivateAppTermsOfServices();

        notification.show(
          t('private.terms_of_service.success'),
          'success',
          DEFAULT_NOTIFICATION_AUTO_HIDE_MS,
        );

        await user.reload();
      } catch (e) {
        Sentry.reportError('Failed to accept terms of service', { error: e });
        dialog.show((props) => (
          <ErrorDialog {...props} text={translateError(t, e)} />
        ));
      }
    },
  });

  const loading = formik.isSubmitting;

  return (
    <FormikProvider value={formik}>
      <Container>
        <Content>
          <Form onSubmit={formik.handleSubmit}>
            <Card>
              <Text>
                <Title>{t('private.terms_of_service.title')}</Title>
                <Description>
                  {t('private.terms_of_service.text')}
                  <Link
                    href={t('private.terms_of_service.tos_link')}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t('private.terms_of_service.tos_link_suffixtext')}.
                  </Link>
                </Description>
              </Text>
              <FormField
                name="approval"
                render={(props) => (
                  <FilledCheckbox
                    {...props}
                    label={t('private.terms_of_service.label')}
                    disabled={loading}
                  />
                )}
              />
              <Box alignX="right">
                <OkButton
                  type="submit"
                  disabled={loading || !formik.isValid}
                  text={t('private.terms_of_service.submit')}
                />
              </Box>
            </Card>
          </Form>
        </Content>
      </Container>
    </FormikProvider>
  );
};

export default TermsOfServiceApproval;
