const routes = {
  login: '/login',
  loginCallback: '/callback',
  idCheckNew: '/id/new',
  idCheckHistory: '/id/history',
  auth: '/auth',
  profile: '/profile',
  validateEmail: '/email/validate',
};

export default routes;
